<template>
	<el-dialog @open="handleOpen" :before-close="handleClose" :visible.sync="imgDialogVisible" width="660px" style="margin-top: -10vh">
		<div class="image-gallery">
			<!-- 预览区域 -->
			<div class="preview">
				<el-image style="width: 600px;height: 600px;" fit="contain" :src="images[currentImageIndex]" class="preview-image" />
				<img alt="下载" @click="downLoadImage(images[currentImageIndex])" class="download-img" src="@/assets/images/download.png" />
			</div>
			<!-- 图片缩略图 -->
			<div class="thumbnails">
				<el-image fit="contain" v-for="(image, index) in images" :key="index" :src="image" @click="selectImage(index)" class="thumbnail" />
			</div>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			currentImageIndex: 0, // 当前预览图片的索引
			intervalId: null
		};
	},
	props:{
		imgDialogVisible: false,
		images:[]
	},
	mounted() {
		// 组件挂载后启动自动切换图片的定时器
		this.startAutoPlay();
	},
	beforeDestroy() {
		// 组件销毁前清除定时器
		this.stopAutoPlay();
	},
	methods: {
		handleOpen(){
			this.currentImageIndex = 0;
		},
		handleClose(){
			this.$emit('update:imgDialogVisible', false);
		},
		downLoadImage(imageSrc) {
			window.open(imageSrc, '_blank');
		},
		selectImage(index) {
			this.currentImageIndex = index;
			this.stopAutoPlay();
		},
		startAutoPlay() {
			// 设置定时器，每隔5秒切换图片
			this.intervalId = setInterval(() => {
				// 当达到图片数组末尾时，重置索引到第一张图片
				this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
			}, 5000);
		},
		stopAutoPlay() {
			// 清除定时器
			if (this.intervalId) {
				clearInterval(this.intervalId);
				this.intervalId = null;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.image-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.thumbnails {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.thumbnail {
	width: 100px;
	height: 100px;
	margin: 5px;
	cursor: pointer;
	border: #ccc solid 1px;
}

.preview {
	width: 600px; /* 预览图宽度 */
	height: 600px; /* 预览图高度 */
	display: flex;
	align-items: center;
	border: #ccc solid 1px;
	text-align: center;
	justify-content: center;

	.download-img {
		width: 50px;
		height: 50px;
		position: absolute; /* 绝对定位 */
		right: 30px; /* 从右边缘偏移 */
		bottom: 150px; /* 从底边缘偏移 */
		cursor: pointer;
	}
}

.preview-image {
	width: 100%;
	height: auto;
	display: block;
	align-items: center;
}
</style>
