<template>
	<el-container>
		<el-container>
			<el-aside width="250px">
				<img @click="goHome()" style="cursor: pointer;" src="@/assets/logo-big.png" alt="logo" class="logo" />
				<div class="create-btn">
					<el-button type="primary" @click="startCreate()">+ 新建</el-button>
				</div>

				<el-menu active-text-color="#067BEF" default-active="1" @open="handleOpen" @close="handleClose">
					<el-menu-item index="1">
						<i class="el-icon-goods"></i>
						<span slot="title">我的作品</span>
					</el-menu-item>
					<el-menu-item index="2">
						<i class="el-icon-location"></i>
						<span slot="title">推荐</span>
					</el-menu-item>
				</el-menu>

				<!-- 固定在底部的元素 -->
				<div class="fixed-bottom">作品数：{{ recordCount }}</div>
			</el-aside>
			<el-main>
				<div class="user-profile">
					<span class="nickname">
						<el-dropdown @command="dropdownClick">
							<span class="el-dropdown-link">
								{{ userInfo.name }}
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</span>
					<img :src="userInfo.headImg" class="avatar" />
				</div>
				<el-table border :data="tableData" class="record-content">
					<el-table-column type="index" label="序号" width="50" header-align="center" align="center"></el-table-column>
					<el-table-column prop="sourceImg" label="原图" width="200" header-align="center" align="center">
						<template v-slot="scope">
							<el-image style="width: 100px; height: 100px" :src="scope.row.sourceImg" fit="scale-down" class="image-column" />
						</template>
					</el-table-column>
					<el-table-column prop="generateImg" label="成品图" width="200" header-align="center" align="center">
						<template v-slot="scope">
							<el-image style="width: 100px; height: 100px" :src="scope.row.generateImg" fit="scale-down" class="image-column" />
						</template>
					</el-table-column>
					<el-table-column prop="prompt" label="提示词" width="250" header-align="center" align="center"></el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="200" header-align="center" align="center"></el-table-column>
					<el-table-column label="操作" header-align="left" align="left">
						<template slot-scope="scope">
							<el-button type="primary" @click="useItem(scope.row.id)">应用</el-button>
							<el-button @click="deleteItem(scope.row.id)">删除</el-button>
						</template>
					</el-table-column>
					<template slot="empty">
						<div style="text-align: center">
							<el-empty description="没有数据">
								<el-button type="primary" style="width: 200px" @click="startCreate()">+ 新建</el-button>
							</el-empty>
						</div>
					</template>
				</el-table>
				<el-pagination
					class="pagination"
					background
					layout="total, sizes, prev, pager, next, jumper"
					:total="total"
					:current-page="currentPage"
					:page-sizes="[5, 10, 20, 30, 40]"
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
				></el-pagination>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
import Cookies from 'js-cookie';
import { getLastRecord, deleteRecord, loginUserInfo, loginOut } from '@/utils/api';

export default {
	components: {},
	data() {
		return {
			recordCount: 0,
			userInfo: {},
			tableData: [],
			total: 1,
			currentPage: 1,
			pageSize: 10
		};
	},
	mounted() {
		this.loadUserInfo();
		this.loadLastRecord(1);
	},
	methods: {
		goHome(){
			window.open("/", '_self', '', true);
		},
		startCreate(){
			window.open("/#/Draw");
		},
		useItem(id) {
			
		},
		deleteItem(id) {
			deleteRecord(id).then((res) => {
				console.log('deleteRecord=====' + JSON.stringify(res));
				this.loadLastRecord(this.currentPage, this.pageSize);
			});
		},
		handleSizeChange(newSize) {
			this.pageSize = newSize;
			this.loadLastRecord(1, newSize);
		},
		handleCurrentChange(newPage) {
			this.currentPage = newPage;
			this.loadLastRecord(newPage, this.pageSize);
		},
		dropdownClick(command) {
			console.log('command=====' + command);
			if (command === 'loginOut') {
				loginOut().then((res) => {
					console.log('loginOut=====' + JSON.stringify(res));
					Cookies.remove('token');
				});
				setTimeout(() => {
					this.$router.push({ path: '/' });
				}, 500); 
			}
		},
		loadLastRecord(pageNo, pageSize) {
			var params = {
				pageNo: pageNo,
				pageSize: pageSize
			};
			getLastRecord(params).then((res) => {
				console.log('getLastRecord=====' + JSON.stringify(res));
				this.recordCount = res.data.total;
				this.tableData = res.data.content;
				this.total = res.data.total;
			});
		},
		handleOpen(key, keyPath) {
			console.log(key, keyPath);
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		},
		loadUserInfo() {
			loginUserInfo().then((res) => {
				this.userInfo = res.data;
			});
		}
	}
};
</script>

<style lang="less">
@import '@/views/Dashboard.less';
</style>
