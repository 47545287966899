<template>
	<div>
		<header class="header">
			<div class="container">
				<div class="logo-nav">
					<img @click="goHome()" style="cursor: pointer;"  src="@/assets/logo-big.png" alt="logo" class="logo" />
					<nav class="nav">
						<ul>
							<li><a href="/">首页</a></li>
							<li><a href="/#/About">关于我们</a></li>
						</ul>
					</nav>
					<div class="login">
						<el-button v-if="!loginFlag" type="primary" @click="openLoginDialog()">登录</el-button>
						<el-button v-else type="primary" @click="toDashboard()">进入控制台</el-button>
					</div>
				</div>
			</div>
		</header>

		<div class="body">
			<el-image src="https://oss.djsmart.vip/app/about02.jpg" fit="scale-down" />
			<div class="hero">
				<div class="title1">AI画图助手</div>
				<div class="title2">释放无限创意，您的AI画图伙伴</div>
				<el-button class="main-btn" type="danger" round>立即体验</el-button>
			</div>
			<div class="desc container">
				<el-image src="https://oss.djsmart.vip/app/about04.jpg" fit="scale-down" />
				<div class="desc-txt">
					<div class="desc-title">关于我们</div>
					<div class="desc-line"></div>
					<div class="desc-content">
						东莞市洞见智能科技有限公司，自2024年崭露头角，坐落于风光秀丽的粤港澳大湾区核心地带——东莞。
						公司汇聚了一批来自知名互联网企业的精英，他们拥有超过10年的行业经验，对项目开发及运营管理有着深刻的理解和丰富的实战经验。
						<br/><br/>洞见智能科技致力于AI人工智能领域的创新与发展，旗下产品经过匠心独运的设计，赢得了广大用户和客户的青睐。
						<br/><br/>我们的团队既稳重务实，又充满激情，对于客户和用户的需求具有敏锐的洞察力和高效的响应能力。
						我们秉持着成为业界领先的人工智能服务商的发展宗旨，砥砺前行，不断开创辉煌。
					</div>
				</div>
			</div>
		</div>

		<footer class="footer">
			<div class="container">
				<p>
					©2024 版权所有 东莞市洞见智能科技有限公司
					<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备2024313776号</a>
				</p>
			</div>
		</footer>
		<LoginDialog :dialogVisible.sync="dialogOpen" />
	</div>
</template>

<script>
import Cookies from 'js-cookie';
import LoginDialog from '@/components/LoginDialog';
import { loginUserInfo } from '@/utils/api';
export default {
	components: {
		LoginDialog
	},
	mounted() {
		this.loadUserInfo();
	},
	data() {
		return {
			dialogOpen: false,
			loginFlag: false //是否已登录
		};
	},
	methods: {
		goHome(){
			window.open("/", '_self', '', true);
		},
		toDashboard() {
			this.$router.push({ path: '/Dashboard' });
		},
		loadUserInfo() {
			console.log('Cookies=============' + Cookies);
			var token = Cookies.get('token');
			if (!token || !token.trim()) {
				return;
			}
			loginUserInfo().then((res) => {
				this.loginFlag = res.data !== null;
			});
		},
		openLoginDialog() {
			this.dialogOpen = true;
		}
	}
};
</script>

<style lang="less">
@import '@/views/about/About.less';
</style>
